@import './variables.scss';

.container {
  border: 1px solid #deedff;
  box-sizing: border-box;
  border-radius: 8px;
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.container:active {
  opacity: 0.5;
}
.title {
  font-family: $font;
  font-style: normal;
  font-weight: bold;
  color: $white-light;
  font-size: 15px;
  width: 100%;
  line-height: 26px;
  margin-left: 10px;
}
.icon {
  width: 25px;
  margin-left: 8px;
  height: 25px;
}
