.sucursal {
  background-color: #f7fcfe;
}
.sub {
  position: fixed;
  z-index: 1;
  width: 100%;
  max-width: 490px;
}

.pedidoindividual {
  padding-top: 320px;
}
.sucursalempty {
  /* Fonts/Bold Medium */
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  margin-top: 25px;
  /* or 111% */
  text-align: center;

  /* Grayscale / Label */
  color: #576875;
}
.subtitle {
  /* Fonts/Text Small */
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;

  /* or 120% */
  text-align: center;
  margin-top: 25px;
  /* Grayscale / Label */
  color: #576875;
}
