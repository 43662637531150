@import './variables.scss';

.container {
  height: 32px;
  background-color: $dark-blue-secondary;
  padding: 6px 10px;
  border-radius: 8px;
  color: white;
}
.container:active {
  opacity: 0.8;
  border: 1px solid #00377a;
  color: white;
}
.container:focus {
  border: 1px solid #00377a;
  color: white;
}

.tagA {
  display: flex;
  text-decoration: none;
  color: $white-light;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.title {
  font-family: $font;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  margin: 0px 5px 0px 2px;
  color: white;
}

.icon {
  width: 18px;
  height: 18px;
  margin: 0px 5px;
}
