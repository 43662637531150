@import './variables.scss';
.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px;
  width: 116px;
  height: 32px;
  position: static;
  left: 56.55%;
  right: 6.39%;
  top: 30%;
  border-radius: 6px;
  bottom: 30%;
}
.title {
  font-family: $font;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.25px;
  color: $white-light;
}
