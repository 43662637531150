@import './variables.scss';

.containerOrders {
  min-height: 100vh;
  background-color: $blue-light;
  padding: 2px 20px 20px 20px;
  position: relative;
}

.quantityOrder {
  font-size: 20px;
  font-weight: 900;
  text-align: center;
  color: black;
}

.containerAmount {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #bdbdbd;
  margin-top: 30px;
}
.total {
  text-align: center;
  font-size: 13px;
  font-weight: 500;
}
.flex {
  display: flex;
  justify-content: center;
}

.containerAmountCobrar {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #c1c1c1;
  min-width: 50%;
  justify-content: flex-end;
  min-height: 80px;
  padding-bottom: 5px;
}

.amountCobrar {
  font-size: 28px;
  font-weight: bolder;
  font-family: $font;
  color: black;
  text-align: center;
}

.number {
  width: 96px;
  font-family: $font;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: $gray-scale;
  margin-top: 4px;
}

.symbolAmount {
  font-size: 16px;
  font-weight: bolder;
  font-family: $font;
  color: black;
  text-align: center;
  margin-right: 3px;
  align-self: center;
  margin-top: 3px;
}

.amountCobrar2 {
  font-size: 28px;
  font-weight: bolder;
  font-family: $font;
  color: #37afff;
  text-align: center;
}

.symbolAmount2 {
  font-size: 16px;
  font-weight: bolder;
  font-family: $font;
  color: #37afff;
  text-align: center;
  align-self: center;
  margin-right: 3px;
  margin-top: 3px;
}
.containerAmountComision {
  display: flex;
  flex-direction: column;
  min-width: 50%;
  justify-content: flex-end;
  min-height: 80px;
  padding-bottom: 5px;
}
.containerCards {
  margin-top: 15px;
}

.containerCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  height: 100%;
}

.containerNameCard {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  margin: 0px;
}

.name {
  width: 160px;
  font-family: $font;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.25px;
  color: $dark;
}

.buttonText {
  font-size: 13px;
  padding: 6px;
  align-self: center;
}

.container2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 15px;
  position: static;
  height: 107px;
  left: 0px;
  margin-bottom: 15px;
  top: 0px;
  background: $white-light;
  box-sizing: border-box;
  flex: none;
  order: 0;
  flex-grow: 0;
  box-shadow: 0px 3px 10px rgba(61, 66, 70, 0.1);
  border-radius: 16px;
  &:active {
    box-shadow: 0px 3px 20px rgba(61, 66, 70, 0.1);
  }
}

.tagId {
  width: 148px;
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.25px;
  color: $color-primary;
}

.flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 100%;
}
.separator {
  width: 100%;
  height: 0px;
  border-bottom: 1px solid $light-gray;
}
.ordersempty {
  font-family: $font;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 16px;
  /* or 107% */
  text-align: center;
  margin-top: 25px;
  /* Grayscale / Label */
  color: #576875;
}
.subtitle {
  /* Fonts/Text Small */
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;

  /* or 120% */
  text-align: center;
  margin-top: 20px;
  /* Grayscale / Label */
  color: #576875;
}
.nameClient{
  font-family: sans-serif;
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 14px;

/* or 117% */
letter-spacing: 0.25px;

/* Grayscale / Body */
color: #303B44;
}

.date{
  font-family: sans-serif;
font-style: normal;
font-weight: normal;
font-size: 13px;
line-height: 26px;

/* or 200% */
text-align: right;
letter-spacing: 0.25px;

/* Grayscale / Label */
color: #576875;


}

.containerDate {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.symbol{
  font-family: sans-serif;
font-style: normal;
font-weight: bold;
font-size: 12px;
margin-top: 3px;
line-height: 14px;
margin-right: 2px;

/* or 117% */
display: flex;
align-items: center;
letter-spacing: 0.25px;

/* Grayscale / Label */
color: #576875;
}
.completeClient{
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #FF8F3E;
  margin-left: 5px;
  }
  .containerAlert{
    display: flex;
    justify-content: flex-start;
    margin-top: 5px;
  }