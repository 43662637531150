$color-primary: #37afff;
$dark-blue: #37AFFF;
$color-light: #deedff;
$dark-blue-secondary: #008CEA;
$white-light: #fefeff;
$color-received: #ba49ff;
$color-delivered: #37afff;
$color-confirm: #57c157;
$color-canceled: #9eaeba;
$color-pending: #ff8f3e;
$color-error: #da0400;
$gray-scale: #576875;
$dark: #303b44;
$blue-light: #f7fcfe;
$light-gray: #dfe5e7;
$green-light: #f2fffb;
//fonts
$font: sans-serif;
