@import './variables.scss';

.containerFilter {
  padding: 20px 20px 0px 20px;
  height: 110px;
  background: $white-light;
  box-shadow: 0px 3px 10px rgba(61, 66, 70, 0.06);
  border-bottom: 1px solid #dfe5e7;

}

.titleFilter {
  align-self: center;
  text-align: center;
  font-weight: 600;
  font-family: sans-serif;
  font-size: 16px;
  color: black;
  letter-spacing: 0.3px;
}

.arrowIcons {
  width: 60px;
  &:active {
    opacity: 0.7;
  }
}

.containerArrows {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.day {
  font-family: $font;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: $dark;
}

.month {
  font-family: $font;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 26px;
  text-align: center;
  color: $gray-scale;
}
.quantity {
  font-family: $font;
  font-style: normal;
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  color: $color-primary;
  margin-top: 5px;
}

.total {
  display: flex;
  flex-direction: column;
  width: 40%;
}
.totalText {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 26px;
  color: $dark;
  text-align: right;
  letter-spacing: 0.25px;
}
.totalNumber {
  font-family: $font;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  color: $gray-scale;
  text-align: right;
}
.line {
  border-right: 1px solid #dfe5e7;
}

.comision {
  display: flex;
  flex-direction: column;
  width: 40%;
  cursor: pointer;
}
.comision:active {
  opacity: 0.4;
}
.comisionText {
  font-family: $font;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 26px;
  color: $color-primary;
  text-align: left;
}
.comisionNumber {
  font-family: $font;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  color: $color-primary;
}
.alert {
  margin-left: 10px;
}
.month{
font-family: sans-serif;
font-style: normal;
font-weight: bold;
font-size: 15px;
line-height: 16px;
margin-top: 5px;
color: #576875;
}