@import './variables.scss';

.containerHeader {
  height: 200px;
  background-color: $dark-blue;
  padding: 10px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 3px 10px rgba(61, 66, 70, 0.06);
}

.containerIcons {
  display: flex;
  justify-content: space-between;
  max-height: 90px;
  align-items: center;
}

.logoNilus {
  width: 70px;
  height: 32px;
}

.logoWa {
  width: 23px;
  height: 23px;
  margin-right: 3px;
}

.welcomeTitle {
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #FEFEFF;  
}

.containerButtons {
  display: flex;
  justify-content: space-between;
}
