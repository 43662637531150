@import './variables.scss';

.login {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #37afff;

  .logo {
    width: 30%;
    max-width: 160px;
    margin-bottom: 30px;
  }

  form {
    display: flex;
    flex-direction: column;

    input {
      margin-bottom: 20px;
      padding: 2%;
    }

    p {
      font-size: 15px;
      font-weight: '400';
      font-family: 'sans-serif';
      text-align: center;
      max-width: 250px;
      align-self: center;
    }

    button {
      background-color: #3b9018;
      color: white;
      border: none;
      padding: 3%;
      border-radius: 5px;
      min-height: 40px;
      text-transform: capitalize;
      letter-spacing: 0.2px;
    }
  }
}
